import React from "react"
import Layout from "../../components/root/Layout"
import Seo from "../../components/root/Seo"
import Hero from "../../components/modules/HeroPortrait"
import Card from "../../components/elements/Card"
import CheckCircle from "../../../assets/heroicons-0.4.1/optimized/solid/check-circle.svg"
import EmailForm from "../../components/elements/MentorEmailForm"
import ConfettiTime from "../../components/elements/ConfettiTime"

export default function Mentees({ data, location }) {
  const card = {
    type: data.mentor.data.role.text.toUpperCase(),
    headline: data.mentor.data.description.text,
    list: Object.keys(data.mentor.data.benefits[0]).map(
      key => data.mentor.data.benefits[0][key].text
    ),
    pill: "pill-primary",
    btn: "btn-secondary ",
    link: "/mentors",
  }
  const { type, pill, headline, list } = card
  const {
    card_desc,
    hero_paragraph,
    main_title,
    main_title_accent,
    hero_image,
  } = data.prismicMentorPage.data
  return (
    <Layout>
      <ConfettiTime location={location} />
      <Seo title="Mentors" cardUrl="https://ik.imagekit.io/sld/mentorHero_sQkgY2AEQNjy.png"/>
      <Hero img={hero_image.localFile.childImageSharp.fluid}>
        <h2 className="text-4xl md:text-5xl font-bold mb-3 leading-none">
          {main_title.text}{" "}
          <span className="text-brand-blue">{main_title_accent.text}</span>
        </h2>
        <p className="text-xl">{hero_paragraph.text}</p>
      </Hero>
      <div className="bg-brand-blue text-white md:mb-32">
        <div className="container-lg py-8 md:py-16 lg:py-24">
          <div className="grid grid-cols-1 md:grid-cols-2">
            <p className="text-xl mb-5 md:mb-0 md:text-2xl md:px-8">
              {card_desc.text}
            </p>
            <div className="md:-mb-48">
              <Card>
                <div class={`${pill} mr-2 inline-block mb-3`}>{type}</div>
                <h4 className="text-4xl leading-tight md:text-5xl font-bold mb-5">
                  {headline}
                </h4>
                <ul className="">
                  {list.map(item => (
                    <li>
                      <div className="grid grid-cols-9 mb-3">
                        <CheckCircle
                          className={`${
                            type === "MENTEE"
                              ? "text-brand-green"
                              : "text-brand-blue"
                          } glyph-md col-span-1`}
                        />
                        <p className="col-span-8 mt-1">{item}</p>
                      </div>
                    </li>
                  ))}
                </ul>
              </Card>
            </div>
          </div>
        </div>
      </div>
      <div className="container py-8 md:pt-0 md:pb-16 ">
        <h4 className="text-2xl md:text-3xl font-medium pt-8">
          Inspire a generation
        </h4>
        <h5 className="text-4xl md:text-5xl font-bold mb-2">Get Started Now</h5>
        <a href="https://app.archup.co.uk/login" className="btn text-center text-2xl">Sign Up</a>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  {
    mentor: prismicRoleType(data: { role: { text: { eq: "Mentor" } } }) {
      data {
        benefits {
          first {
            text
          }
          second {
            text
          }
          third {
            text
          }
        }
        description {
          text
        }
        role {
          text
        }
      }
    }
    prismicMentorPage {
      data {
        card_desc {
          text
        }
        hero_paragraph {
          text
        }
        main_title {
          text
        }
        main_title_accent {
          text
        }
        hero_image {
          localFile {
            childImageSharp {
              fluid(maxWidth: 780) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
      }
    }
  }
`
