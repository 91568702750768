import React from "react"
import Img from "gatsby-image"
import Confetti from "../../../assets/confetti.svg"
import Confetti2 from "../../../assets/confetti2.svg"

const Hero = ({ children, img, reverse }) => (
  <div className="container-lg py-8 md:py-16 lg:py-24 text-gray-800">
    <div className="grid grid-cols-1 lg:grid-cols-5 md:gap-4">
      {reverse ? (
        <>
          <div className="relative lg:col-span-2  hidden lg:block lg:mr-16 lg:-ml-12 mb-8 lg:mt-0 flex justify-center">
            <div className="max-w-sm">
              <Confetti className="absolute top-0 right-0 -mt-24 confetti" />
              <Confetti2
                className="absolute bottom-0 left-0 -mr-12 -mb-24 confetti"
                style={{ zIndex: 1 }}
              />

              <Img
                fluid={img}
                alt="Lady explaining her point using her hands to emphasize"
                className="relative sm:-mx-2 w-full h-64 lg:h-full rounded object-cover object-top"
              />
            </div>
          </div>
          <div className="md:-ml-6 flex flex-col justify-center lg:col-span-3 md:px-12">
            {children}
          </div>
        </>
      ) : (
        <>
          <div className="md:-mr-6 flex flex-col justify-center lg:col-span-3">
            {children}
          </div>
          <div className="relative lg:col-span-2  hidden lg:block lg:ml-16 lg:-mr-12 mt-8 lg:mt-0 flex justify-center">
            <div className="max-w-sm">
              <Confetti className="absolute top-0 right-0 -mt-24 confetti" />
              <Confetti2
                className="absolute bottom-0 left-0 -ml-12 -mb-24 confetti"
                style={{ zIndex: 1 }}
              />

              <Img
                fluid={img}
                alt="Lady explaining her point using her hands to emphasize"
                className="relative sm:-mx-2 w-full h-64 lg:h-full rounded object-cover object-top"
              />
            </div>
          </div>
        </>
      )}
    </div>
  </div>
)

export default Hero
